const EASContractAddress = "0xC2679fBD37d54388Ce493F1DB75320D236e1815e"; // Sepolia v0.26

export const EAS_CONFIG = {
	address: EASContractAddress,
	version: "0.26",
	chainId: 1,
};

export const EAS_TICKET_SCHEMA = {
	fields: [
		{ name: "Guest_UID", label: "Guest", type: "string", },
		{ name: "Issuer_UID", label: "Issuer", type: "string",  },
		{ name: "Location", label: "Location", type: "string",  },
		//{ name: "Date", label: "Date", type: "string",  },
		//{ name: "Time", label: "Time", type: "string",  },
		{ name: "Event", label: "Event", type: "string",  },
		{ name: "Issuer_Name", label: "Issuer Name", type: "string",  },
		{ name: "Issuer_Twitter", label: "Issuer Twitter", type: "string",  },
		{ name: "Issuer_Telegram", label: "Issuer Telegram", type: "string",  },
		{ name: "TokenScript_About", label: "About TokenScript", type: "string",  },
		{ name: "TokenScript_Attestation_Interface", label: "TokenScript File", type: "string",  },
		{ name: "Linked_Contract", label: "Linked Contract", type: "string",  },
	]
};