import "./styles.css";
import {EAS_TICKET_SCHEMA} from "../../src/common";

declare global {
	interface Window {
		EASSDK: any
		attest: Index
		ethereum: any
	}
}

const SERVER_BASE = document.location.hostname === "localhost" ? "http://localhost:8081/" : "";

class Index {

	constructor() {

		const query = new URLSearchParams(document.location.hash.substring(1));

		if (query.has("attestation")){
			this.validateAttestation(query.get("attestation"))
		} else {
			document.getElementById("create-view").style.display = "block";
		}
	}

	private showLoader(){
		document.getElementById("loader").style.display = "flex";
	}

	private hideLoader(){
		document.getElementById("loader").style.display = "none";
	}

	async createAttestation(){

		this.showLoader();

		try {

			const email = (document.getElementById("email-address-input") as HTMLInputElement).value;

			const result = await this.apiRequest("create-attestation", {
				email
			});

			this.updateAttestationDetails(result.attestationData, result.signedAttestation, result.passId);

			// (document.getElementById("eas-link") as HTMLLinkElement).href = "https://easscan.org/offchain/url/" + result.urlEncoded;

			document.location.hash = result.urlEncoded;

		} catch (e){
			console.error(e);
			alert(e.message);
		}

		this.hideLoader();
	}

	private async apiRequest(endpoint: string, data?: Object){

		const res = await fetch(SERVER_BASE + "api/" + endpoint, {
			method: data ? "POST" : "GET",
			headers: {
				"Accept": "application/json",
				"Content-type": "application/json"
			},
			body: JSON.stringify(data)
		})

		const result = await res.json();

		if (res.status !== 200){
			throw new Error("Server error: " + result.message);
		}

		return result;
	}

	private updateAttestationDetails(attestationData, signedAttestation, passId){

		const time = new Date(signedAttestation.sig.message.time * 1000)

		let rows = `
				<tr>
					<td>Date/Time</td>
					<td>${time.toLocaleString()}</td>
				</tr>
			`;

		for (const field of EAS_TICKET_SCHEMA.fields){

			const value = attestationData[field.name].value;

			rows += `
				<tr>
					<td>${field.label}</td>
					<td>${value}</td>
				</tr>
			`;
		}

		document.querySelector("#details-table tbody").innerHTML = rows;

		(document.getElementById("pass-url") as HTMLLinkElement).href = "https://pub1.pskt.io/" + passId;
		(document.getElementById("attestation-json") as HTMLTextAreaElement).value = JSON.stringify(signedAttestation);

		document.getElementById("create-view").style.display = "none";
		document.getElementById("attestation-view").style.display = "block";

	}

	async validateAttestation(urlAttestation: string){

		this.showLoader();

		try {

			const res = await this.apiRequest("validate-attestation", {
				urlAttestation
			});

			this.updateAttestationDetails(res.attestationData, res.signedAttestation, res.passId)

		} catch (e){
			console.error(e);
			alert(e.message);
		}

		this.hideLoader();
	}
}

window.attest = new Index();
